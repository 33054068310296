<template>
  <div>
    <ingredient-list-add-new
      :is-add-new-ingredient-sidebar-active.sync="
        isAddNewIngredientSidebarActive
      "
      @refetch-data="refetchData"
    />
    <ingredient-list-edit
      :is-edit-ingredient-sidebar-active.sync="isEditIngredientSidebarActive"
      :ingredient="selectedIngredient"
      @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>adet</label> -->
          </b-col>
          <!-- Search -->
          <b-col cols="12" md="12">
            <div
              class="d-flex align-items-center justify-content-between flex-wrap"
            >
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mw-33"
                :placeholder="$t('search')"
              />
              <div class="mt-1 mt-md-0">
                <b-button
                  v-b-modal.modalIngredients
                  class="mt-1 mt-sm-0 ml-0 ml-sm-1"
                  variant="primary"
                  @click="
                    () => {
                      fetchIngredientVisibilities();
                    }
                  "
                >
                  <span class="text-nowrap">{{ $t("ingredientFilter") }}</span>
                </b-button>
                <b-button
                  class="ml-0 ml-md-1"
                  variant="primary"
                  @click="
                    isAddNewIngredientSidebarActive =
                      !isAddNewIngredientSidebarActive
                  "
                >
                  <span class="text-nowrap">{{ $t("ingredientAdd") }}</span>
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refIngredientListTable"
        class="position-relative"
        :items="fetchIngredients"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('noRecords')"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Ingredient -->
        <template #cell(ingredientname)="data">
          <b-link @click="openEditSideBar(data)">
            <span class="align-middle ml-50 text-secondary">
              {{ data.item.ingredientname }}
            </span>
          </b-link>
        </template>
        <template #cell(copy)="data">
          <b-link
            v-b-modal.modalIngredientCopy
            @click="selectedIngredient = data.item"
          >
            <div class="d-flex">
              <feather-icon class="text-success" icon="CopyIcon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("copy")
              }}</small>
            </div>
          </b-link>
        </template>
        <template #cell(cinsname)="data">
          {{ data.item.cinsname == "Yoğun Yem" ? $t("grain") : $t("roughage") }}
        </template>
        <template #cell(delete)="data">
          <b-link
            @click="
              deleteIngredient({
                plantid: $store.state.app.selectedPlantId,
                ingredientid: data.item.id,
              })
            "
          >
            <div class="d-flex">
              <feather-icon class="text-danger" icon="Trash2Icon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("delete")
              }}</small>
            </div>
          </b-link>
        </template>

        <template #cell(edit)="data">
          <b-link @click="openEditSideBar(data)">
            <div class="d-flex">
              <feather-icon icon="EditIcon" />
              <small class="d-none d-lg-inline align-middle ml-50 text-dark">{{
                $t("edit")
              }}</small>
            </div>
          </b-link>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }} </span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalIngredients"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <ingredient-modal
        :all-ingredients="allIngredients.data"
        @submit-ingredients="updateIngredientVisibility"
      />
      <ingredient-copy-modal
        :selected-ingredient="selectedIngredient"
        @copy-ingredient="copyIngredient"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BPagination,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useIngredientList from "./useIngredientList";
import IngredientListAddNew from "./IngredientListAddNew.vue";
import IngredientListEdit from "./IngredientListEdit.vue";
import IngredientModal from "../../components/IngredientModal.vue";
import IngredientCopyModal from "../../components/IngredientCopyModal.vue";
import { getUserData } from "@/auth/utils";
import VueI18n from "@/libs/i18n";
export default {
  components: {
    IngredientListAddNew,
    IngredientListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BPagination,
    // vSelect,
    IngredientModal,
    IngredientCopyModal,
  },
  data() {
    return {
      // typeOptions: [],
      // genderOptions: [
      //   { label: "Dişi", value: "Dişi" },
      //   { label: "Erkek", value: "Erkek" },
      // ],
    };
  },
  beforeRouteEnter(to, from, next) {
    if (to.name == "ingredients") {
      to.meta.breadcrumb = [];
      to.meta.breadcrumb.push({
        to: "/",
        text: VueI18n.t("ingredients"),
        active: true,
      });
      to.meta.breadcrumb.push({
        text: VueI18n.t("ingredients"),
        active: true,
      });
    }
    next();
  },
  setup({ emit }) {
    const isAddNewIngredientSidebarActive = ref(false);
    const isEditIngredientSidebarActive = ref(false);
    const selectedIngredient = ref({});
    const userData = getUserData();
    const selectedChanged = ref(0);

    const openEditSideBar = (item) => {
      selectedIngredient.value = item.item;
      isEditIngredientSidebarActive.value =
        !isEditIngredientSidebarActive.value;
      selectedChanged.value++;
    };
    const {
      fetchIngredients,
      fetchIngredientVisibilities,
      fetchEsitlikler,
      tableColumns,
      perPage,
      currentPage,
      totalIngredients,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIngredientListTable,
      refetchData,
      allIngredients,
      updateIngredientVisibility,
      copyIngredient,
      // UI
      perPageOptions,

      // Extra Filters
      typeFilter,
      genderFilter,

      deleteIngredient,
    } = useIngredientList();
    fetchEsitlikler();
    return {
      // Sidebar
      isAddNewIngredientSidebarActive,
      isEditIngredientSidebarActive,
      openEditSideBar,
      allIngredients,
      selectedIngredient,
      fetchIngredients,
      fetchIngredientVisibilities,
      tableColumns,
      perPage,
      currentPage,
      totalIngredients,
      dataMeta,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIngredientListTable,
      refetchData,
      perPageOptions,
      updateIngredientVisibility,
      copyIngredient,
      // Filter
      avatarText,
      // UI
      userData,
      selectedChanged,
      // Extra Filters
      typeFilter,
      genderFilter,
      deleteIngredient,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
