<template>
  <b-sidebar
    id="add-new-ingredient-sidebar"
    :visible="isAddNewIngredientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-xxl"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankingredient).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("ingredientAdd") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankingredient
    ).refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group
              :label="$t('code')"
              label-cols-sm="3"
              label-size="sm"
              label-for="kod"
            >
              <b-form-input
                id="kod"
                v-model="blankIngredientData.kod"
                size="sm"
                autofocus
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group
              :label="$t('name')"
              label-cols-sm="3"
              label-size="sm"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="blankIngredientData.ingredientname"
                size="sm"
                autofocus
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Fiyat -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="fiyat"
          >
            <b-form-group
              :label="$t('price')"
              label-cols-sm="3"
              label-size="sm"
              label-for="fiyat"
            >
              <b-form-input
                id="fiyat"
                v-model="blankIngredientData.fiyat"
                size="sm"
                autofocus
                type="number"
                step="any"
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-card-actions
            :title="$t('values')"
            action-collapse
            :collapsed="true"
          >
            <!-- Values -->
            <div class="border-bottom mb-2">
              <b-form-group
                :label="$t('search')"
                label-size="sm"
                label-cols-sm="2"
                label-for="search"
              >
                <b-form-input
                  id="search"
                  v-model="searchValues"
                  size="sm"
                  autofocus
                  autocomplete="off"
                  trim
                  placeholder=""
                />
              </b-form-group>
            </div>
            <div class="values-container">
              <div
                v-for="value in searchedValues"
                :key="value.id"
                rules="required"
                name="fiyat"
              >
                <b-form-group
                  :label="value.nutrientname"
                  :label-for="value.kod"
                  label-size="sm"
                  label-class="label-class"
                  label-cols-sm="6"
                  label-cols="12"
                  label-align="left"
                >
                  <BInputGroup size="sm">
                    <b-form-input
                      :id="value.nutrientname"
                      size="sm"
                      step="any"
                      type="number"
                      :disabled="
                        esitlikler.some((x) => x.besinMaddesiid === value.id)
                      "
                      trim
                      placeholder=""
                      :value="value.deger"
                      @input="(e) => checkEquations(value.kod, true, e)"
                    />
                    <b-input-group-append size="sm" is-text>
                      <span>{{ value.birim }}</span>
                    </b-input-group-append>
                  </BInputGroup>
                </b-form-group>
              </div>
            </div>
          </b-card-actions>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import { mapState } from "vuex";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    BCardActions,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewIngredientSidebarActive",
    event: "update:is-add-new-ingredient-sidebar-active",
  },
  props: {
    isAddNewIngredientSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      errorList: [],
      required,
      blankIngredientData: {},
      values: [],
      cins: [
        {
          label: this.$t("grain"),
          value: 1,
        },
        {
          label: this.$t("roughage"),
          value: 2,
        },
      ],
      formValidation: formValidation,
      searchValues: "",
      lastKmValue: 0,
    };
  },
  computed: {
    ...mapState({
      esitlikler: (state) =>
        state.plantsModule.esitlikler.filter((x) => x.hammaddeid == 0),
    }),
    searchedValues() {
      return this.values.filter(
        (x) =>
          x.nutrientname
            .toLowerCase()
            .includes(this.searchValues.toLowerCase()) ||
          x.kod == this.searchValues
      );
    },
  },

  methods: {
    calculateKmBagimli(e) {
      if (e != this.lastKmValue) {
        var val = (e == 0 ? 1 : e) / this.lastKmValue;
        var a = this.values
          .filter((x) => x.kMBagimli)
          .map(
            (x) =>
              (x.deger = isNaN(
                (val * x.deger).toFixed(getUserData().nutrientdecimal)
              )
                ? 0
                : (val * x.deger).toFixed(getUserData().nutrientdecimal))
          );
        this.lastKmValue = e;
      }
    },
    async checkEquations(kod, isInputChanged = true, e = 0) {
      if (this.values.find((x) => x.kod == kod).orjnkey == 1) {
        if (e == "") e = "0";
        if (
          typeof e == "string" &&
          e &&
          e.startsWith("0") &&
          !e.startsWith("0.")
        )
          e = e.replace("0", "1");
        this.calculateKmBagimli(e);
      }
      if (isInputChanged) {
        this.values.find((x) => x.kod == kod).deger = +e;
        this.values = this.values.map((x) => ({
          ...x,
          calculated: false,
        }));
      }

      const regex = /\[(.*?)\]/g;

      const checkCondition = ({
        sartli,
        sartbmid,
        sartbmid2,
        sartdeger,
        sartdeger2,
        sartoperator,
        sartoperator2,
        sartbirlestir,
      }) => {
        if (sartli) {
          var sartbirlestir =
            sartbirlestir == 0 ? "" : sartbirlestir == 1 ? "&&" : "||";
          let sartliBesinMaddesi;
          try {
            sartliBesinMaddesi = this.values.find(
              (x) => x.id === sartbmid
            ).deger;
          } catch {
            sartliBesinMaddesi = 0;
            this.errorList.push(sartbmid);
          }
          sartoperator = sartoperator == "=" ? "==" : sartoperator;
          if (sartbirlestir != "") {
            let sartliBesinMaddesi2;

            try {
              sartliBesinMaddesi2 = this.values.find(
                (x) => x.id === sartbmid2
              ).deger;
            } catch {
              sartliBesinMaddesi2 = 0;
              this.errorList.push(sartbmid2);
            }
            const sart1 = eval(
              `${sartliBesinMaddesi} ${sartoperator} ${sartdeger}`
            );
            const sart2 = eval(
              `${sartliBesinMaddesi2} ${sartoperator2} ${sartdeger2}`
            );
            return eval(`${sart1} ${sartbirlestir} ${sart2}`);
          }
          return eval(`${sartliBesinMaddesi} ${sartoperator} ${sartdeger}`);
        } else {
          return true;
        }
      };

      this.esitlikler.map((x) => {
        if (!!(x.formul.split("[B" + kod + "]").length - 1)) {
          const index = this.values.findIndex((y) => y.id === x.besinMaddesiid);
          if (!this.values[index].calculated) {
            if (!x.sartli || (x.sartli && checkCondition(x))) {
              const besinMadddeleriInString = x.formul.match(regex);
              let formul = x.formul;
              const mathString = besinMadddeleriInString.map((m) => {
                var value = this.values.find(
                  (y) => y.kod == m.replace("[B", "").replace("]", "")
                ).deger;
                var hasValue = !!parseFloat(value);

                return (formul = formul
                  .replaceAll(m, hasValue ? value : 0)
                  .replaceAll(",", "."));
              });

              this.values[index].deger = eval(
                mathString[mathString.length - 1]
              ).toFixed(getUserData().nutrientdecimal);
              this.values[index].calculated = true;
              if (
                this.values[index].deger == Infinity ||
                isNaN(this.values[index].deger)
              ) {
                this.values[index].deger = 0;
                this.values[index].calculated = false;
              }

              this.checkEquations(
                this.values[index].kod,
                false,
                this.values[index].deger
              );
            }
          }
        }
      });
    },
    async fetchNewIngredient() {
      var { data } = await this.$http.get("/Ingredients/GetNewIngredient/", {
        headers: { culinfo: getUserData().cultureinfo },
      });
      this.blankIngredientData = data.resultdata[0];
      this.values = [...this.blankIngredientData.values];
      this.blankIngredientData.cins = 2;
    },
    async changed(val) {
      if (val == true) await this.fetchNewIngredient();
      this.$emit("update:is-add-new-ingredient-sidebar-active", val);
    },
    onSubmit() {
      var tinyCompanyIngredient = {
        values: [],
      };
      tinyCompanyIngredient.id = this.blankIngredientData.id;
      tinyCompanyIngredient.cins = this.blankIngredientData.cins;
      tinyCompanyIngredient.ingredientname =
        this.blankIngredientData.ingredientname;
      tinyCompanyIngredient.kod = this.blankIngredientData.kod;
      tinyCompanyIngredient.fiyat = parseFloat(this.blankIngredientData.fiyat);
      this.values.map((x) => {
        if (x.deger != 0) {
          tinyCompanyIngredient.values.push({
            id: x.id,
            deger: parseFloat(x.deger),
          });
        }
      });
      store
        .dispatch(
          "companyProductsModule/addCompanyProduct",
          tinyCompanyIngredient
        )
        .then(() => {
          this.$emit("refetch-data");
          this.$emit("update:is-add-new-ingredient-sidebar-active", false);
        });
    },
    async resetblankingredient() {
      blankIngredientData = await this.fetchNewIngredient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-ingredient-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.values-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px 2rem;

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
  }
}
.label-class {
  align-items: center;
  justify-content: start;
  display: flex;
}
</style>
