import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import VueI18n from "@/libs/i18n";
import axios from "@axios";
// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useIngredientsList() {
  // Use toast
  const toast = useToast();

  const refIngredientListTable = ref(null);
  const allIngredients = ref({
    data: [],
    key: 0,
  });
  // Table Handlers
  const tableColumns = [
    { key: "kod", label: VueI18n.t("code"), sortable: false },
    {
      key: "ingredientname",
      label: VueI18n.t("ingredientName"),
      sortable: false,
    },
    { key: "edit", label: VueI18n.t("edit") },
    { key: "copy", label: VueI18n.t("copy") },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const ingredint = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refIngredientListTable.value
      ? refIngredientListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refIngredientListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const fetchIngredients = async (ctx, callback) => {
    var ingredient = await store
      .dispatch(
        "plantsModule/fetchIngredients",
        store.state.app.selectedPlantId
      )
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("ingredients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    if (searchQuery.value != "")
      ingredient = ingredient.filter(
        (x) =>
          x.ingredientname
            .toLowerCase()
            .startsWith(searchQuery.value.toLowerCase()) ||
          x.kod.toLowerCase().startsWith(searchQuery.value.toLowerCase())
      );

    return ingredient;
  };

  const fetchIngredientVisibilities = async (ctx, callback) => {
    allIngredients.value.data = await store
      .dispatch(
        "plantsModule/fetchIngredientVisibilities",
        store.state.app.selectedPlantId
      )
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("ingredients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    allIngredients.value.key += 1;
    return allIngredients.value;
  };

  const updateIngredientVisibility = async (ctx) => {
    await store
      .dispatch("plantsModule/updateIngredientVisibility", {
        plantid: store.state.app.selectedPlantId,
        values: ctx,
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("ingredients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
    refetchData();
  };

  const copyIngredient = async (ctx) => {
    var { data } = await axios.get(
      `/Ingredients/GetIngredientValuesById/${ctx.id}`,
      {
        headers: {
          tip: "0",
          plantid: store.state.app.selectedPlantId,
        },
      }
    );
    var tinyCompanyIngredient = {
      values: [],
    };
    tinyCompanyIngredient.id = -555;
    tinyCompanyIngredient.cins = 2;
    tinyCompanyIngredient.ingredientname = ctx.ingredientname;
    tinyCompanyIngredient.kod = ctx.kod;
    tinyCompanyIngredient.fiyat = parseFloat(ctx.fiyat);
    tinyCompanyIngredient.values = data.resultdata;

    var result = await store.dispatch(
      "companyProductsModule/addCompanyProduct",
      tinyCompanyIngredient
    );
    var message =
      result.message == "-1" ? "codeAlreadyExists" : "nameAlreadyExists";
    if (result.status) {
      toast({
        component: ToastificationContent,
        props: {
          title: VueI18n.t("successful"),
          icon: "CheckIcon",
          variant: "success",
          text: VueI18n.t("ingredient") + " " + VueI18n.t("copied"),
        },
      });
      refetchData();
    } else {
      toast({
        component: ToastificationContent,
        props: {
          text: VueI18n.t(message),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  };

  const fetchEsitlikler = async (ctx) => {
    await store.dispatch("plantsModule/getNutrientEquations");
  };

  const deleteIngredient = (ctx) => {
    store
      .dispatch("plantsModule/deleteIngredient", ctx)
      .then((response) => {
        refetchData();
        toast({
          component: ToastificationContent,
          props: {
            title: VueI18n.t("successful"),
            icon: "CheckIcon",
            variant: "success",
            text: VueI18n.t("deleted", { type: VueI18n.t("ingredients") }),
          },
        });
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("ingredients") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchIngredients,
    fetchEsitlikler,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refIngredientListTable,
    deleteIngredient,
    allIngredients,
    refetchData,
    fetchIngredientVisibilities,
    updateIngredientVisibility,
    copyIngredient,
    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  };
}
