<template>
  <b-modal
    id="modalIngredients"
    scrollable
    :title="$t('ingredientFilter')"
    cancel-title="Close"
    :ok-title="$t('ok')"
    ok-only
    @ok="submitIngredient"
  >
    <b-form-group :label="$t('search')">
      <b-form-input @input="search" />
    </b-form-group>
    <b-table
      small
      :fields="[
        { key: 'index', label: $t('#') },
        { key: 'deger', label: $t('selected') },
        { key: 'ingredientname', label: $t('ingredient') },
      ]"
      :items="allIngredientsSearch"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(deger)="data">
        <b-form-checkbox
          :checked="data.item.deger"
          @change="changed(data.item)"
        />
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BTable,
} from "bootstrap-vue";
export default {
  components: {
    BModal,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BTable,
  },
  props: {
    allIngredients: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      allIngredientsSearch: [],
      selectedIngredients: [],
    };
  },
  watch: {
    allIngredients: function (val) {
      this.allIngredientsSearch = [...val];
      this.selectedIngredients = [];
    },
  },
  mounted: function () {
    this.allIngredientsSearch = [...this.allIngredients];
  },
  methods: {
    search(val) {
      this.allIngredientsSearch = this.allIngredients.filter((item) => {
        return item.ingredientname.toLowerCase().includes(val.toLowerCase());
      });

      this.selectedIngredients.forEach((item) => {
        const index = this.allIngredientsSearch.findIndex(
          (i) => i.id === item.id
        );
        if (index !== -1) this.allIngredientsSearch.splice(index, 1, item);
      });
    },
    changed(item) {
      item = { ...item, deger: !item.deger };

      if (this.selectedIngredients.findIndex((i) => i.id === item.id) === -1) {
        this.selectedIngredients.push(item);
      } else {
        this.selectedIngredients = this.selectedIngredients.filter(
          (i) => i.id !== item.id
        );
      }
    },
    submitIngredient() {
      this.$emit("submit-ingredients", this.selectedIngredients);
    },
  },
};
</script>
