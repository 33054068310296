<template>
  <b-sidebar
    id="edit-ingredient-sidebar"
    :visible="isEditIngredientSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-xxl"
    shadow
    backdrop
    no-header
    right
    @hidden="formValidation(resetblankingredient).resetForm"
    @change="(val) => changed(val)"
  >
    <template #default="{ hide }">
      <equation-list-edit
        :is-edit-equation-sidebar-active.sync="isEditEquationSidebarActive"
        :equation.sync="selectedEquation"
        @refetch-data="fetchEquations(true)"
      />
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("ingredientEdit") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="formValidation(
      resetblankingredient
    ).refFormObserver"
      >
        <!-- Form -->

        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Kod -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="kod"
          >
            <b-form-group :label="$t('code')" label-for="kod" label-cols-sm="3">
              <b-form-input
                id="kod"
                v-model="blankIngredientData.kod"
                autofocus
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                size="sm"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ad -->
          <validation-provider
            #default="validationContext"
            rules="required"
            name="name"
          >
            <b-form-group
              :label="$t('ingredientName')"
              :label-for="blankIngredientData.ingredientname"
              label-cols-sm="3"
            >
              <b-form-input
                :id="blankIngredientData.ingredientname"
                v-model="blankIngredientData.ingredientname"
                autofocus
                :state="
                  formValidation(resetblankingredient).getValidationState(
                    validationContext
                  )
                "
                trim
                placeholder=""
                size="sm"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Values -->
          <b-card-actions title="Değerler" action-collapse :collapsed="true">
            <!-- Values -->
            <div class="border-bottom mb-2">
              <b-form-group
                :label="$t('search')"
                label-for="search"
                label-cols-sm="1"
                label-align="center"
              >
                <b-form-input
                  id="search"
                  v-model="searchValues"
                  autofocus
                  autocomplete="off"
                  trim
                  placeholder=""
                  size="sm"
                />
              </b-form-group>
            </div>
            <div class="values-container">
              <div
                v-for="value in searchedValues"
                :key="value.kod"
                rules="required"
                :name="value.kod"
              >
                <b-form-group
                  :label="value.nutrientname + ' (' + value.kod + ')'"
                  :label-for="value.kod.toString()"
                  label-size="sm"
                  label-class="label-class"
                  label-cols-sm="6"
                  label-cols="12"
                  label-align="left"
                >
                  <BInputGroup :id="value.nutrientname" size="sm" class="">
                    <b-form-input
                      :id="value.kod.toString()"
                      step="any"
                      type="number"
                      trim
                      size="sm"
                      placeholder=""
                      :disabled="
                        filteredEsitlikler.some(
                          (x) => x.besinMaddesiid === value.id
                        )
                      "
                      :style="
                        filteredEsitlikler.some(
                          (x) => x.besinMaddesiid === value.id
                        )
                          ? '  background-color: #f2f2f2; color: #000000;'
                          : ''
                      "
                      :value="value.deger"
                      @input="(e) => checkEquations(value.kod, true, e)"
                    />
                    <b-input-group-append is-text>
                      <small>{{ value.birim }}</small>
                    </b-input-group-append>
                  </BInputGroup>
                </b-form-group>
              </div>
            </div>
          </b-card-actions>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("update") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { getUserData } from "@/auth/utils";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";
import EquationListEdit from "../Nutrients/Components/EquationListEdit.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    EquationListEdit,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    BCardActions,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isEditIngredientSidebarActive",
    event: "update:is-edit-ingredient-sidebar-active",
  },
  props: {
    isEditIngredientSidebarActive: {
      type: Boolean,
      required: true,
    },
    ingredient: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedEquation: {},
      isEditEquationSidebarActive: false,
      required,
      blankIngredientData: {},
      values: [],
      cins: [
        {
          label: this.$t("grain"),
          value: 1,
        },
        {
          label: this.$t("roughage"),
          value: 2,
        },
      ],
      formValidation: formValidation,
      updateKey: 0,
      searchValues: "",
      lastKmValue: 0,
      modal: null,
    };
  },
  computed: {
    ...mapState({
      esitlikler: (state) => state.plantsModule.esitlikler,
    }),
    filteredEsitlikler() {
      return this.esitlikler.filter(
        (x) => x.hammaddeid === this.blankIngredientData.id || x.hammaddeid == 0
      );
    },
    searchedValues() {
      return this.values.filter(
        (x) =>
          x.nutrientname
            .toLowerCase()
            .includes(this.searchValues.toLowerCase()) ||
          x.kod.toString() == this.searchValues
      );
    },
  },
  methods: {
    calculateKmBagimli(e) {
      if (e != this.lastKmValue) {
        var val = (e == 0 ? 1 : e) / this.lastKmValue;
        var a = this.values
          .filter((x) => x.kMBagimli)
          .map(
            (x) =>
              (x.deger = isNaN(
                (val * x.deger).toFixed(getUserData().nutrientdecimal)
              )
                ? 0
                : (val * x.deger).toFixed(getUserData().nutrientdecimal))
          );
        this.lastKmValue = e;
      }
    },
    async fetchEquations(b) {
      await this.$store.dispatch("plantsModule/getNutrientEquations", b);

      await this.fetchNewIngredient();
    },
    async checkEquations(kod, isInputChanged = true, e = 0) {
      let errorList = [];
      if (this.values.find((x) => x.kod == kod).orjnkey == 1) {
        if (e == "") e = "0";
        if (
          typeof e == "string" &&
          e &&
          e.startsWith("0") &&
          !e.startsWith("0.")
        )
          e = e.replace("0", "1");
        this.calculateKmBagimli(e);
      }

      if (isInputChanged) {
        this.values.find((x) => x.kod == kod).deger = +e;
        this.values = this.values.map((x) => ({
          ...x,
          calculated: false,
        }));
      }
      const regex = /\[(.*?)\]/g;
      const checkCondition = ({
        sartli,
        sartbmid,
        sartbmid2,
        sartdeger,
        sartdeger2,
        sartoperator,
        sartoperator2,
        sartbirlestir,
      }) => {
        if (sartli) {
          var sartbirlestir =
            sartbirlestir == 0 ? "" : sartbirlestir == 1 ? "&&" : "||";
          let sartliBesinMaddesi;
          try {
            sartliBesinMaddesi = this.values.find(
              (x) => x.id === sartbmid
            ).deger;
          } catch {
            sartliBesinMaddesi = 0;
            errorList.push(sartbmid);
          }
          sartoperator = sartoperator == "=" ? "==" : sartoperator;
          sartoperator2 = sartoperator2 == "=" ? "==" : sartoperator2;
          if (sartbirlestir != "") {
            let sartliBesinMaddesi2;
            try {
              sartliBesinMaddesi2 = this.values.find(
                (x) => x.id === sartbmid2
              ).deger;
            } catch {
              sartliBesinMaddesi2 = 0;
              errorList.push(sartbmid2);
            }
            const sart1 = eval(
              `${sartliBesinMaddesi} ${sartoperator} ${sartdeger}`
            );
            const sart2 = eval(
              `${sartliBesinMaddesi2} ${sartoperator2} ${sartdeger2}`
            );
            return eval(`${sart1} ${sartbirlestir} ${sart2}`);
          }
          return eval(`${sartliBesinMaddesi} ${sartoperator} ${sartdeger}`);
        } else {
          return true;
        }
      };

      this.filteredEsitlikler.map((x) => {
        errorList = [];
        if (!!(x.formul.split("[B" + kod + "]").length - 1)) {
          const index = this.values.findIndex((y) => y.id === x.besinMaddesiid);
          if (!this.values[index].calculated) {
            if (!x.sartli || (x.sartli && checkCondition(x))) {
              const besinMadddeleriInString = x.formul.match(regex);
              let formul = x.formul;
              const mathString = besinMadddeleriInString.map((m) => {
                let value = 0;
                try {
                  value = this.values.find(
                    (y) => y.kod == m.replace("[B", "").replace("]", "")
                  ).deger;
                } catch (error) {
                  value = 0;
                  errorList.push(m);
                }
                var hasValue = !!parseFloat(value);
                return (formul = formul
                  .replaceAll(m, hasValue ? value : 0)
                  .replaceAll(",", "."));
              });
              if (errorList.length > 0) {
                const bsnMdString = errorList.length
                  ? this.$t("nutrients").toLowerCase()
                  : this.$t("nutrient").toLowerCase();
                const msg =
                  // `${x.hammadde} hammeddesinde ${
                  //   x.besinMaddesi
                  // } besin maddesinin eşitliğinde ${errorList.join(", ")}
                  //       kodlu ${bsnMdString} bulunmaktadır. Ancak bu besin maddesi firmanız besin maddeleri arasında bulunmamaktadır. Bu
                  //       ${bsnMdString}ni daha önce silmişsiniz. Lütfen eşitliği düzenleyiniz.`;
                  this.$t("equationErrorMsg", {
                    hammadde: x.hammadde,
                    besinMaddesi: x.besinMaddesi,
                    errorList: errorList.join(", "),
                    bsnMdString: bsnMdString,
                  });
                this.$bvModal
                  .msgBoxConfirm(
                    msg,

                    {
                      title: this.$t("error"),
                      size: "sm",
                      buttonSize: "sm",
                      okVariant: "danger",
                      okTitle: this.$t("edit"),
                      okOnly: true,
                      cancelTitle: this.$t("cancel"),
                      footerClass: "p-2",
                      hideHeaderClose: false,
                      centered: true,
                    }
                  )
                  .then((value) => {
                    if (value) {
                      this.selectedEquation = x;

                      this.isEditEquationSidebarActive = true;
                    }
                  })
                  .catch((err) => {
                    // An error occurred
                  });
              }
              this.values[index].deger = eval(
                mathString[mathString.length - 1]
              ).toFixed(getUserData().nutrientdecimal);

              this.values[index].calculated = true;

              if (
                this.values[index].deger == Infinity ||
                isNaN(this.values[index].deger)
              ) {
                this.values[index].deger = 0;
                this.values[index].calculated = false;
              }
              this.checkEquations(
                this.values[index].kod,
                false,
                this.values[index].deger
              );
            }
          }
        }
      });
    },
    async fetchNewIngredient() {
      var { data } = await this.$http.get(
        `/Ingredients/GetIngredientValuesById/${this.ingredient.id}`,
        {
          headers: {
            culinfo: getUserData().cultureinfo,
            tip: "0",
            plantid: this.$store.state.app.selectedPlantId,
          },
        }
      );
      this.blankIngredientData = this.ingredient;
      this.values = data.resultdata;
      this.lastKmValue = this.values.find((x) => x.orjnkey == 1).deger;

      this.values.map((x) => {
        this.checkEquations(x.kod, false, x.deger);
      });
      this.blankIngredientData.cins = 2;
    },
    async changed(val) {
      if (val == true) await this.fetchNewIngredient();
      this.$emit("update:is-edit-ingredient-sidebar-active", val);
    },
    onSubmit() {
      var ingredient = {};
      var values = [];
      ingredient.id = this.blankIngredientData.id;
      ingredient.cins = this.blankIngredientData.cins;
      ingredient.ingredientname = this.blankIngredientData.ingredientname;
      ingredient.kod = this.blankIngredientData.kod;

      this.values.map((x) => {
        if (x.deger != x.orjdeger) {
          values.push({
            id: x.id,
            deger: parseFloat(x.deger),
          });
        }
      });
      store
        .dispatch("companyProductsModule/updateCompanyProduct", {
          ingredient,
          values,
        })
        .then((isOk) => {
          this.$emit("refetch-data");
          this.$emit("update:is-edit-ingredient-sidebar-active", false);
          if (isOk)
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "CheckIcon",
                variant: "success",
                text: this.$t("updated", { type: this.$t("ingredient") }),
              },
            });
          else
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("successful"),
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: this.$t("updated", { type: this.$t("ingredient") }),
              },
            });
        });
    },
    async resetblankingredient() {
      blankIngredientData = await this.fetchNewIngredient;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#edit-ingredient-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.values-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0px 2rem;

  @include media-breakpoint-down(xs) {
    grid-template-columns: 1fr;
  }
}

.label-class {
  align-items: center;
  justify-content: start;
  display: flex;
}
</style>
