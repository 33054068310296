<template>
  <b-modal
    id="modalIngredientCopy"
    scrollable
    :title="$t('ingredientCopy')"
    cancel-title="Close"
    :ok-title="$t('ok')"
    ok-only
    centered
    @ok="submitIngredient"
  >
    <span class="font-weight-bold"> {{ $t("copiedIngredient") }}: </span>
    <p>({{ ingredient.kod }}) {{ ingredient.ingredientname }}</p>
    <b-form-group class="mt-2" :label="$t('code')">
      <b-form-input v-model="newCode" />
    </b-form-group>
    <b-form-group class="mt-2" :label="$t('ingredientName')">
      <b-form-input v-model="newName" />
    </b-form-group>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BTable,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
  },
  props: {
    selectedIngredient: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      ingredient: {},
      newName: "",
      newCode: "",
    };
  },
  watch: {
    selectedIngredient: function (val) {
      this.ingredient = JSON.parse(JSON.stringify(val));

      this.newName = "";
      this.newCode = "";
    },
  },
  mounted: function () {
    this.ingredient = JSON.parse(JSON.stringify(this.selectedIngredient));
  },
  methods: {
    submitIngredient() {
      this.ingredient.ingredientname = this.newName;
      this.ingredient.kod = this.newCode;
      this.$emit("copy-ingredient", this.ingredient);
    },
  },
};
</script>
